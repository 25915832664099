var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useState } from "react";
import React from "react";
import Cookies from "js-cookie";
import sortArray from "sort-array";
var NewsSearchContext = createContext();
export var NewsSearchProvider = function (_a) {
    var _b;
    var children = _a.children;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    //News State
    var _c = __read(React.useState(0), 2), randQuery = _c[0], setRandQuery = _c[1];
    var _d = __read(React.useState([]), 2), news = _d[0], setNews = _d[1];
    var _e = __read(useState({}), 2), rowsSearchData = _e[0], setRowsSearchData = _e[1];
    var _f = __read(useState(false), 2), advSearch = _f[0], setAdvSearch = _f[1];
    var _g = __read(React.useState(""), 2), searchedTerm = _g[0], setSearchedTerm = _g[1];
    var _h = __read(React.useState("list"), 2), view = _h[0], setView = _h[1];
    var _j = __read(React.useState(true), 2), newsIsLoading = _j[0], setNewsIsloading = _j[1];
    var _k = __read(React.useState(true), 2), categoriesIsLoading = _k[0], setCategoriesIsLoading = _k[1];
    var _l = __read(useState(false), 2), filtersIsLoading = _l[0], setFiltersIsLoading = _l[1];
    var _m = __read(React.useState(0), 2), count = _m[0], setCount = _m[1];
    //Filter State
    var _o = __read(React.useState([]), 2), selectedCategory = _o[0], setSelectedCategory = _o[1];
    var _p = __read(React.useState([]), 2), categories = _p[0], setCategories = _p[1];
    //Search State
    var _q = __read(React.useState(""), 2), searchInput = _q[0], setSearchInput = _q[1];
    var _r = __read(React.useState(), 2), yearInput = _r[0], setYearInput = _r[1];
    var _s = __read(React.useState([]), 2), months = _s[0], setMonths = _s[1];
    // Pagination State
    var _t = __read(React.useState(0), 2), page = _t[0], setPage = _t[1];
    var _u = __read(React.useState(1), 2), pageCount = _u[0], setPageCount = _u[1];
    var _v = __read(useState([]), 2), rows = _v[0], setRows = _v[1];
    useEffect(function () {
        //order by first selected filter
        sortArray(rows, {
            by: ["order"],
        });
        // First, check if the first element is not "where"
        if (rows.length > 0 && rows[0].condition !== "where") {
            rows[0].condition = "where";
        }
        for (var i = 1; i < rows.length; i++) {
            if (rows[i].field !== rows[i - 1].field) {
                rows[i].condition = "and";
            }
        }
        var searchData = {
            query: searchInput !== searchedTerm ? searchInput : searchedTerm,
            page: page + 1,
            filters: [
                {
                    New: {
                        sort: {
                            date_creation: {
                                order: "desc",
                            },
                        },
                        conditions: rows,
                    },
                },
            ],
        };
        setRowsSearchData({
            sort: {
                date_creation: {
                    order: "desc",
                },
            },
            conditions: rows,
        });
        if (!advSearch)
            fetchNews(searchData);
    }, [rows, page, searchedTerm, randQuery]);
    // highest order
    function getHighestOrder(array) {
        var highestOrder = 15;
        for (var i = 0; i < array.length; i++) {
            if (array[i].order > highestOrder) {
                highestOrder = array[i].order;
            }
        }
        return highestOrder;
    }
    useEffect(function () {
        fetchCategories();
    }, []);
    //Fetch news
    var fetchNews = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        function postData(url, data) {
            if (url === void 0) { url = "/".concat(langCode, "/api/search/"); }
            if (data === void 0) { data = {}; }
            return __awaiter(this, void 0, void 0, function () {
                var response;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, fetch(url, {
                                method: "POST",
                                mode: "cors",
                                cache: "no-cache",
                                credentials: "same-origin",
                                headers: {
                                    "X-CSRFToken": CSRF_TOKEN,
                                    "Content-Type": "application/json",
                                    // 'Content-Type': 'application/x-www-form-urlencoded',
                                },
                                redirect: "follow",
                                referrerPolicy: "no-referrer",
                                body: JSON.stringify(data), // body data type must match "Content-Type" header
                            })];
                        case 1:
                            response = _a.sent();
                            return [2 /*return*/, response.json()];
                    }
                });
            });
        }
        var CSRF_TOKEN;
        return __generator(this, function (_a) {
            setNewsIsloading(true);
            CSRF_TOKEN = Cookies.get("csrftoken");
            postData("/".concat(langCode, "/api/search/"), data).then(function (data) {
                var nbrPages = data.count / 10;
                setPageCount(Math.ceil(nbrPages));
                var news = data.results.map(function (data) {
                    return data.result;
                });
                setNews(news);
                setCount(data.count);
                setNewsIsloading(false);
            });
            return [2 /*return*/];
        });
    }); };
    // Handle page change
    var handlePageClick = function (e) {
        setPage(e.selected);
        window.scrollTo(0, 0);
    };
    //Fetch categorie
    var fetchCategories = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, categories;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setFiltersIsLoading(true);
                    return [4 /*yield*/, fetch("/".concat(langCode, "/api/search/fields/?table=New&context=filters&no_fetching"))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    categories = data.filter(function (obj) { return obj.field_name == "categorie"; });
                    setCategories(categories[0].values);
                    setFiltersIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    //Reset filter
    var resetFilters = function () {
        setSelectedCategory([]);
        setMonths([]);
        setYearInput("");
        setRows([]);
    };
    //search news
    var searchNews = function () {
        setPage(0);
        setSearchedTerm(searchInput);
    };
    //Category function
    var handleCategoryChange = function (item) {
        setPage(0);
        //get latest selected
        var latestSelected = selectedCategory
            .filter(function (x) { return !item.includes(x); })
            .concat(item.filter(function (x) { return !selectedCategory.includes(x); }));
        var newCategory = {
            order: 0,
            condition: "or",
            field: "categorie.id",
            operator: "is",
            value: latestSelected[0].value,
        };
        if (item.length > selectedCategory.length) {
            setSelectedCategory(item);
            setRows(function (prevState) {
                return __spreadArray(__spreadArray([], __read(prevState), false), [newCategory], false);
            });
            if (rows.some(function (obj) { return Object.values(obj).includes("categorie.id"); })) {
                var indx = rows.findIndex(function (obj) { return obj["field"] === "categorie.id"; });
                newCategory.order = rows[indx].order;
            }
            else {
                var bigOrder = getHighestOrder(rows);
                newCategory.order = bigOrder + 1;
            }
        }
        else {
            //get the removed option
            var removedOption_1 = selectedCategory
                .filter(function (x) { return !item.includes(x); })
                .concat(item.filter(function (x) { return !selectedCategory.includes(x); }));
            setSelectedCategory(item);
            setRows(function () { return rows.filter(function (row) { return row.value != removedOption_1[0].value; }); });
        }
    };
    // handleKeyDown function
    var handleKeyDown = function (event) {
        if (event.key === "Enter") {
            setPage(0);
            setSearchedTerm(searchInput);
        }
    };
    // View functions
    var handleView = function () {
        if (view === "thubmnails") {
            setView("list");
        }
        else {
            setView("thubmnails");
        }
    };
    //Search functions
    var handleSearchInput = function (e, tab) {
        if (tab === void 0) { tab = 4; }
        if (tab != 4)
            setAdvSearch(true);
        setSearchInput(e.target.value);
    };
    //handle year change
    var handleYear = function (e) {
        setPage(0);
        var newYear = {
            order: 0,
            condition: "or",
            field: "date_creation_annee",
            operator: "is",
            value: e.target.value,
        };
        var newValue = e.target.value;
        if (newValue != "") {
            setYearInput(newValue);
            if (rows.findIndex(function (row) { return row.field === "date_creation_annee"; }) == -1) {
                setRows(function (prevStat) { return __spreadArray(__spreadArray([], __read(prevStat), false), [newYear], false); });
            }
            else {
                var rowsCopy = __spreadArray([], __read(rows), false);
                var rowIndex = rowsCopy.findIndex(function (row) { return row.field === "date_creation_annee"; });
                if (rowIndex !== -1) {
                    rowsCopy[rowIndex] = __assign(__assign({}, rowsCopy[rowIndex]), { value: newValue });
                    setRows(rowsCopy);
                }
            }
            if (rows.some(function (obj) { return Object.values(obj).includes("date_creation_annee"); })) {
                var indx = rows.findIndex(function (obj) { return obj["field"] === "date_creation_annee"; });
                newYear.order = rows[indx].order;
            }
            else {
                var bigOrder = getHighestOrder(rows);
                newYear.order = bigOrder + 1;
            }
        }
        else {
            setYearInput("");
            setRows(function () { return rows.filter(function (row) { return row.field != "date_creation_annee"; }); });
        }
    };
    //handle month change
    var handleMonthChange = function (month) {
        setPage(0);
        //get latest selected
        var latestSelected = months
            .filter(function (x) { return !month.includes(x); })
            .concat(month.filter(function (x) { return !months.includes(x); }));
        if (month.length > months.length) {
            var newMonth_1 = {
                order: 0,
                condition: "or",
                field: "date_creation_mois",
                operator: "is",
                value: latestSelected[0].value,
            };
            setMonths(month);
            if (rows.some(function (obj) { return Object.values(obj).includes("date_creation_mois"); })) {
                var indx = rows.findIndex(function (obj) { return obj["field"] === "date_creation_mois"; });
                newMonth_1.order = rows[indx].order;
            }
            else {
                var bigOrder = getHighestOrder(rows);
                newMonth_1.order = bigOrder + 1;
            }
            setRows(function (prevState) {
                return __spreadArray(__spreadArray([], __read(prevState), false), [newMonth_1], false);
            });
        }
        else {
            //get the removed option
            var removedOption_2 = months
                .filter(function (x) { return !month.includes(x); })
                .concat(month.filter(function (x) { return !months.includes(x); }));
            setMonths(month);
            if (removedOption_2[0]) {
                setRows(function () {
                    return rows.filter(function (row) { return row.value != removedOption_2[0].value; });
                });
            }
        }
    };
    return (_jsx(NewsSearchContext.Provider, __assign({ value: {
            handleCategoryChange: handleCategoryChange,
            // setCategories,
            handleView: handleView,
            setSearchInput: setSearchInput,
            handleSearchInput: handleSearchInput,
            setSearchedTerm: setSearchedTerm,
            handleYear: handleYear,
            handleMonthChange: handleMonthChange,
            setMonths: setMonths,
            setNewsIsloading: setNewsIsloading,
            setCategoriesIsLoading: setCategoriesIsLoading,
            searchNews: searchNews,
            handlePageClick: handlePageClick,
            resetFilters: resetFilters,
            handleKeyDown: handleKeyDown,
            setPage: setPage,
            // setData,
            count: count,
            pageCount: pageCount,
            page: page,
            news: news,
            rows: rows,
            selectedCategory: selectedCategory,
            view: view,
            categories: categories,
            // data,
            searchInput: searchInput,
            yearInput: yearInput,
            months: months,
            newsIsLoading: newsIsLoading,
            categoriesIsLoading: categoriesIsLoading,
            filtersIsLoading: filtersIsLoading,
            rowsSearchData: rowsSearchData,
            setAdvSearch: setAdvSearch,
            setRandQuery: setRandQuery,
        } }, { children: children })));
};
export default NewsSearchContext;
